var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import './styles.css';
import { initializeMemeCard } from './memegen';
import { initializeStakingCard } from './staking';
import { initializeTicketsCard } from './tickets';
import { Connection, PublicKey } from '@solana/web3.js';
import { cardContent } from './cardcontent'; // Import cardContent from the new file
import songUrl from '../assets/song.mp4';
var audio = new Audio(songUrl);
audio.volume = 0.88; // Set volume to 88% of max
var isPlaying = false;
// Solana program details
export var programID = new PublicKey('RAPRxaJc8Xz2bqD3deoQLqT5noS8zsw1zWwBu95mijo');
export var raprMintAddress = new PublicKey('RAPRz9fd87y9qcBGj1VVqUbbUM6DaBggSDA58zc3N2b');
export var usdcMintAddress = new PublicKey('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v');
export var connection = new Connection('https://mainnet.helius-rpc.com/?api-key=3e197c77-6df6-458d-8718-50b41ad3971a', 'confirmed');
var content = document.getElementById('content');
var exitButton = document.getElementById('exitButton');
var walletButton = document.getElementById('walletButton');
function fadeElement(element, fadeIn, duration, callback) {
    element.style.opacity = fadeIn ? '0' : '1';
    element.style.transition = "opacity ".concat(duration, "ms");
    setTimeout(function () {
        element.style.opacity = fadeIn ? '1' : '0';
        if (callback)
            setTimeout(callback, duration);
    }, 50);
}
var buttonInfo = [
    { name: "STAKING", fontSize: "25px" },
    { name: "TICKETS", fontSize: "25px" },
    { name: "MEME", fontSize: "25px" }
];
function pushState(state, replace) {
    if (replace === void 0) { replace = false; }
    if (replace) {
        history.replaceState(state, '');
    }
    else {
        history.pushState(state, '');
    }
}
function showMenu() {
    var cardElement = document.querySelector('.card');
    if (cardElement) {
        fadeElement(cardElement, false, 500, function () {
            updateMenuContent();
        });
    }
    else {
        updateMenuContent();
    }
    var exitButton = document.getElementById('exitButton');
    if (exitButton) {
        exitButton.innerHTML = '<span class="button-text">HOME</span>';
    }
    var centerContainer = document.querySelector('.center-container');
    if (centerContainer) {
        centerContainer.style.display = 'flex';
        fadeElement(centerContainer, true, 500);
    }
    pushState({ action: 'showMenu' }, true);
}
function updateMenuContent() {
    content.innerHTML = "\n        <div id=\"menu\">\n            ".concat(buttonInfo.map(function (button, index) { return "\n                <button class=\"menuButton\" style=\"font-size: ".concat(button.fontSize, ";\" data-index=\"").concat(index, "\"><span class=\"button-text\">").concat(button.name, "</span></button>\n            "); }).join(''), "\n        </div>\n    ");
    var logo = document.getElementById('logo');
    if (logo) {
        logo.style.display = 'block';
        fadeElement(logo, true, 500);
    }
    var menuElement = document.getElementById('menu');
    if (menuElement) {
        fadeElement(menuElement, true, 500);
    }
    // Attach event listeners to menu buttons
    var menuButtons = document.querySelectorAll('.menuButton');
    menuButtons.forEach(function (button) {
        button.addEventListener('click', function () {
            var index = parseInt(this.getAttribute('data-index') || '0', 10);
            showCard(index);
        });
    });
}
function showCard(index) {
    var card = cardContent[index];
    var menuElement = document.getElementById('menu');
    var centerContainer = document.querySelector('.center-container');
    pushState({ action: 'showCard', index: index });
    if (menuElement)
        fadeElement(menuElement, false, 500);
    if (centerContainer) {
        fadeElement(centerContainer, false, 500, function () {
            centerContainer.style.display = 'none';
            content.innerHTML = "\n                <div class=\"card ".concat(index === 2 ? 'meme-card' : '', "\" data-index=\"").concat(index, "\">\n                    <div class=\"card-header\">").concat(card.title, "</div>\n                    <div class=\"card-content\">\n                        ").concat(card.content, "\n                    </div>\n                </div>\n            ");
            var cardElement = document.querySelector('.card');
            if (cardElement) {
                cardElement.style.removeProperty('margin-top');
                cardElement.style.removeProperty('top');
                fadeElement(cardElement, true, 500, function () {
                    // Initialize card content based on index after fade-in
                    if (index === 0) {
                        initializeStakingCard(wallet);
                    }
                    else if (index === 1) {
                        initializeTicketsCard(wallet);
                    }
                    else if (index === 2) {
                        initializeMemeCard();
                    }
                    // If wallet is connected, update the content immediately
                    if (wallet && wallet.publicKey) {
                        if (index === 0 || index === 1) {
                            var infoElement = index === 0 ? document.getElementById('stakingInfo') : document.getElementById('ticketsInfo');
                            if (infoElement) {
                                infoElement.innerHTML = '<p>Loading...</p>';
                                updateCardContent();
                            }
                        }
                    }
                });
            }
        });
    }
    // Update exit button text
    if (exitButton) {
        exitButton.innerHTML = '<span class="button-text">HOME</span>';
    }
}
export var wallet = null;
function connectWallet() {
    return __awaiter(this, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!window.solana) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, window.solana.connect()];
                case 2:
                    _a.sent();
                    return [2 /*return*/, window.solana];
                case 3:
                    err_1 = _a.sent();
                    console.error('Failed to connect wallet', err_1);
                    alert('Failed to connect wallet. Please try again.');
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 6];
                case 5:
                    console.error('Solana wallet not found');
                    alert('Solana wallet not found. Please install a Solana wallet extension.');
                    _a.label = 6;
                case 6: return [2 /*return*/, null];
            }
        });
    });
}
// Custom event for wallet changes
export var walletChangeEvent = new Event('walletChange');
function checkLogoMenuIntersection() {
    var logo = document.getElementById('logo');
    var menu = document.getElementById('menu');
    if (!logo || !menu) {
        console.warn('Logo or menu element not found');
        return;
    }
    var logoRect = logo.getBoundingClientRect();
    var menuRect = menu.getBoundingClientRect();
    if (logoRect.bottom > menuRect.top + menuRect.height * 0.25) {
        logo.style.opacity = '1';
        logo.classList.add('scaled-downdown');
    }
    else if (logoRect.bottom > menuRect.top) {
        logo.style.opacity = '1';
        logo.classList.add('scaled-down');
    }
    else {
        logo.style.opacity = '1';
        logo.classList.remove('scaled-down');
    }
}
function createTicker(text, targetElement) {
    // Repeat the text enough times to ensure it's wider than the container
    var repeatedText = text.repeat(20); // Adjust this number as needed
    // Create two spans to create a seamless loop
    targetElement.innerHTML = "\n        <span>".concat(repeatedText, "</span>\n        <span>").concat(repeatedText, "</span>\n    ");
}
window.addEventListener('DOMContentLoaded', function () {
    showMenu();
    // Create the top bar structure with the ticker and correct button layout
    var topBar = document.getElementById('topBar');
    if (topBar) {
        topBar.innerHTML = "\n            <div id=\"tickerContainer\">\n                <div class=\"ticker-content\"></div>\n            </div>\n            <div id=\"buttonContainer\">\n                <button id=\"exitButton\"><span class=\"button-text\">HOME</span></button>\n                <button id=\"walletButton\"><span class=\"button-text\">CONNECT</span></button>\n            </div>\n        ";
        var tickerContent = topBar.querySelector('.ticker-content');
        if (tickerContent) {
            createTicker(" SPECIAL MONEY FRIENDS • ", tickerContent);
        }
        // Reinitialize the wallet button
        var walletButton_1 = document.getElementById('walletButton');
        if (walletButton_1) {
            walletButton_1.addEventListener('click', function () { return __awaiter(void 0, void 0, void 0, function () {
                var stakingInfo, ticketsInfo, addressText;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(wallet && wallet.publicKey)) return [3 /*break*/, 1];
                            // Disconnect wallet
                            wallet = null;
                            walletButton_1.innerHTML = '<span class="button-text">CONNECT</span>';
                            stakingInfo = document.getElementById('stakingInfo');
                            ticketsInfo = document.getElementById('ticketsInfo');
                            if (stakingInfo)
                                stakingInfo.innerHTML = '<p>Please connect your wallet to view staking information.</p>';
                            if (ticketsInfo)
                                ticketsInfo.innerHTML = '<p>Please connect your wallet to view ticket information.</p>';
                            return [3 /*break*/, 3];
                        case 1: return [4 /*yield*/, connectWallet()];
                        case 2:
                            wallet = _a.sent();
                            if (wallet && wallet.publicKey) {
                                addressText = wallet.publicKey.toString().slice(0, 6);
                                walletButton_1.innerHTML = "<span class=\"button-text\">".concat(addressText, "</span>");
                                // Update the content of staking and tickets cards
                                updateCardContent();
                            }
                            _a.label = 3;
                        case 3:
                            // Dispatch custom event
                            window.dispatchEvent(walletChangeEvent);
                            return [2 /*return*/];
                    }
                });
            }); });
        }
        // Reinitialize the exit button
        var exitButton_1 = document.getElementById('exitButton');
        if (exitButton_1) {
            exitButton_1.addEventListener('click', showMenu);
        }
    }
    // Initialize the logo click event
    var logoElement = document.getElementById('logo');
    if (logoElement) {
        logoElement.addEventListener('click', function () {
            if (isPlaying) {
                audio.pause();
                isPlaying = false;
            }
            else {
                audio.play();
                isPlaying = true;
            }
        });
    }
    // We'll call checkLogoMenuIntersection after a short delay to ensure the DOM is fully loaded
    setTimeout(checkLogoMenuIntersection, 100);
});
function updateCardContent() {
    return __awaiter(this, void 0, void 0, function () {
        var currentCard, index;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    currentCard = document.querySelector('.card');
                    if (!currentCard) return [3 /*break*/, 5];
                    index = parseInt(currentCard.getAttribute('data-index') || '0', 10);
                    if (!(index === 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, initializeStakingCard(wallet)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2:
                    if (!(index === 1)) return [3 /*break*/, 4];
                    return [4 /*yield*/, initializeTicketsCard(wallet)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [3 /*break*/, 8];
                case 5: 
                // If no card is currently displayed, update both
                return [4 /*yield*/, initializeStakingCard(wallet)];
                case 6:
                    // If no card is currently displayed, update both
                    _a.sent();
                    return [4 /*yield*/, initializeTicketsCard(wallet)];
                case 7:
                    _a.sent();
                    _a.label = 8;
                case 8: return [2 /*return*/];
            }
        });
    });
}
window.addEventListener('popstate', function (event) {
    if (event.state && event.state.action === 'showCard') {
        showCard(event.state.index);
    }
    else {
        showMenu();
    }
});
